<template>
  <div
    class="ca-spot ca-option"
    :class="{ chosen: chosen }"
    @click="updateChoice(option, 'spot'); updateURLChoice(url, 'spotURL')"
  >
    <div>
      <template v-if="chosen">
        <div class="sokal-media-container">
          <video class="spot-video sokal-media-cover" autoplay muted loop playsinline="true">
            <source :src="changeMediaSrc" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </template>
      <template v-else>
        <v-img class="spot-img" :src="changeMediaSrc" alt="" />
      </template>
    </div>

    <h2 class="ca-card-title mb-0 text-center text-capitalize">{{ option }}</h2>
  </div>
</template>

<script>
import updateChoice from "../mixins/mixin";
import updateURLChoice from "../mixins/mixin";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      playSpot: false,
    };
  },
  props: {
    option: String,
    jpg: String,
    gif: String,
    video: String,
    url: String
  },
  mixins: [updateChoice, updateURLChoice],
  computed: {
    ...mapGetters(["getChoice"]),
    changeMediaSrc() {
      return this.chosen ? this.video : this.jpg;
    },
    chosen() {
      return this.getChoice("spot") === this.option;
    },
    chosenURL() {
      return this.getChoice("spotURL") === this.url;
    }
  },
};
</script>

<style scoped>
.ca-spot {
  height: 100%;
}
@media (min-width: 960px) {
  .ca-spot {
    width: 80%;
    max-width: 400px;
    margin: auto;
  }
}
</style>
